import { zeroAddress } from 'viem';
import { getLPTokenPrice } from './getLpPrice';

const baseUrl = 'https://api.dexscreener.com/latest/dex/tokens/';
const lpTokenAddress = '0x39017CDBC84101d737D2410d67FCA3AC0783B90f'; // LP token address

export const dexScreenerUSD = async (chainId: number, tokenAddresses: string[]) => {
  if (!tokenAddresses.length) return new Map<string, string>();

  // Filter out the Ethereum zero address
  const filteredAddresses = tokenAddresses.filter((address) => address !== zeroAddress);

  if (!filteredAddresses.length) return new Map<string, string>();

  const tokenUrl = `${baseUrl}${filteredAddresses.join(',')}`;

  try {
    // Fetch token prices from Dexscreener API
    const result = await fetch(tokenUrl).then((res) => res.json());

    const tokensData = result?.pairs || [];
    const commonTokenUSDValue = new Map<string, string>();

    // Process the token data and find the pair with highest liquidity for each token
    for (const tokenAddress of filteredAddresses) {
      const lowercaseTokenAddress = tokenAddress.toLowerCase();

      // Check if the token is the LP token
      if (lowercaseTokenAddress === lpTokenAddress.toLowerCase()) {
        // Fetch LP token price using the getLPTokenPrice function
        const lpTokenPrice = await getLPTokenPrice(chainId, lpTokenAddress);
        commonTokenUSDValue.set(lpTokenAddress, lpTokenPrice.toString());
      } else {
        // Find all pairs for this token
        const tokenPairs = tokensData.filter((pair: any) => 
          pair.baseToken.address.toLowerCase() === lowercaseTokenAddress ||
          pair.quoteToken.address.toLowerCase() === lowercaseTokenAddress
        );

        // Sort pairs by liquidity in USD (descending order)
        tokenPairs.sort((a: any, b: any) => b.liquidity.usd - a.liquidity.usd);

        if (tokenPairs.length > 0) {
          const highestLiquidityPair = tokenPairs[0];
          const tokenPrice = parseFloat(highestLiquidityPair.priceUsd);

          if (tokenPrice) {
            commonTokenUSDValue.set(tokenAddress, tokenPrice.toString());
          }
        }
      }
    }

    return commonTokenUSDValue;
  } catch (error) {
    console.warn('Error while fetching token price:', error);
    return new Map<string, string>();
  }
};