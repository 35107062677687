import { dexScreenerUSD } from "./dexScreenerPrice";
import { publicClient } from './wagmi'
import { pancakePairV2ABI } from "@pancakeswap/sdk";
import { Address } from "viem";

export const fetchLPDetails = async (
  lpTokenAddress: Address,
  chainId: number
): Promise<{ reserve0: number; reserve1: number; totalSupply: number, token0: string, token1: string }> => {
  // Initialize the client
  const client = publicClient({ chainId });

  // Fetch reserves, token0, token1, and total supply from the LP token contract
  try {
    // Fetch reserves (amount of token0 and token1 in the pool)
    const [reserve0, reserve1] = await client.readContract({
      abi: pancakePairV2ABI,
      address: lpTokenAddress,
      functionName: 'getReserves',
    });

    // Fetch the addresses of token0 and token1
    const token0: string = await client.readContract({
      abi: pancakePairV2ABI,
      address: lpTokenAddress,
      functionName: 'token0',
    });

    const token1: string = await client.readContract({
      abi: pancakePairV2ABI,
      address: lpTokenAddress,
      functionName: 'token1',
    });

    // Fetch total supply of the LP token
    const totalSupply: bigint = await client.readContract({
      abi: pancakePairV2ABI,
      address: lpTokenAddress,
      functionName: 'totalSupply',
    });

    // Convert reserves and total supply to numbers (assumes they're in standard decimal format)
    const res0 = Number(reserve0);  // Reserve of token0
    const res1 = Number(reserve1);  // Reserve of token1
    const supply = Number(totalSupply);  // Total supply of LP tokens

    return {
      reserve0: res0,
      reserve1: res1,
      totalSupply: supply,
      token0,
      token1,
    };
  } catch (error) {
    console.error('Error fetching LP details:', error);
    return {
      reserve0: 0,
      reserve1: 0,
      totalSupply: 0,
      token0: '',
      token1: ''
    };
  }
};

// Calculate LP token price based on the reserves and token prices
export const getLPTokenPrice = async (
  chainId: number,
  lpTokenAddress: Address,
): Promise<number> => {
  // Fetch LP details (reserves and tokens)
  const { reserve0, reserve1, totalSupply, token0, token1 } = await fetchLPDetails(lpTokenAddress, chainId);

  // Use dexScreenerUSD to fetch prices of token0 and token1
  const tokenPrices = await dexScreenerUSD(chainId, [token0, token1]);

  const token0Price = parseFloat(tokenPrices.get(token0) || '0');
  const token1Price = parseFloat(tokenPrices.get(token1) || '0');

  // Calculate the total value of the reserves in USD
  const totalValueInUSD = (reserve0 * token0Price) + (reserve1 * token1Price);

  // Calculate the price of each LP token
  const lpTokenPrice = totalValueInUSD / totalSupply;
  console.log('Lp token price', lpTokenPrice)

  return lpTokenPrice;
}